<template>
  <b-card
    class="datacenteroutbound-edit-wrapper"
  >
    <!-- form -->
    <b-form id="datacenteroutboundForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库名称"
            label-for="wh_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="wh_name"
              size="sm"
              v-model="datacenteroutbound.wh_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库类型Id"
            label-for="warehouse_type_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_type_id"
              size="sm"
              v-model="datacenteroutbound.warehouse_type_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库类型名称"
            label-for="warehouse_type_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_type_name"
              size="sm"
              v-model="datacenteroutbound.warehouse_type_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品名称"
            label-for="product_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_name"
              size="sm"
              v-model="datacenteroutbound.product_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="datacenteroutbound.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部商品名称"
            label-for="out_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_name"
              size="sm"
              v-model="datacenteroutbound.out_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部商品ID"
            label-for="out_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_id"
              size="sm"
              v-model="datacenteroutbound.out_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出库状态Id"
            label-for="status_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="status_id"
              size="sm"
              v-model="datacenteroutbound.status_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出库状态名称"
            label-for="status_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="status_name"
              size="sm"
              v-model="datacenteroutbound.status_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="预期出库数量"
            label-for="qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="qty"
              size="sm"
              v-model="datacenteroutbound.qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="实际出库数量"
            label-for="true_qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="true_qty"
              size="sm"
              v-model="datacenteroutbound.true_qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出库时间"
            label-for="outbound_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="outbound_time"
              size="sm"
              v-model="datacenteroutbound.outbound_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="create_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="create_time"
              size="sm"
              v-model="datacenteroutbound.create_time"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import datacenteroutboundStore from './datacenteroutboundStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      datacenteroutbound: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('datacenteroutbound')) store.registerModule('datacenteroutbound', datacenteroutboundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('datacenteroutbound')) store.unregisterModule('datacenteroutbound')
    })

    const edit = function() {
      store.dispatch('datacenteroutbound/edit', {id: this.id}).then(res => {
        this.datacenteroutbound = res.data.data
      })
    }

    const view = function() {
      store.dispatch('datacenteroutbound/view', {id: this.id}).then(res => {
        this.datacenteroutbound = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('datacenteroutbound/save', this.datacenteroutbound).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>